<template>
  <div class="page_my_address right_connent">
    <div class="title">我的地址</div>
    <div class="connent_wrap">
      <div
        class="address_item"
        v-for="(item, index) in address_List"
        :key="index"
      >
        <div class="name">{{ item.region }}{{ item.street }}</div>
        <div class="center">
          <div class="left">{{ item.consignee }} {{ item.mobile }}</div>
          <div class="right">
            <div class="del_btn" @click="is_del_address(item.id, index)">
              <img src="@/assets/mine/del_icon.png" alt="" />
              删除
            </div>
            <div class="edit_btn" @click="edit_btn(item, index)">
              <img src="@/assets/mine/edit_icon.png" alt="" />
              编辑
            </div>
          </div>
        </div>
        <div
          @click="chang_is_default(item)"
          :class="item.is_default == 1 ? 'is_defult on' : 'is_defult '"
        >
          <i></i>
          默认地址
        </div>
      </div>

      <div
        class="no_data"
        style="
          text-align: center;
          color: #999999;
          margin-top: 100px;
        "
        v-show="show_nodata"
      >
        <img style="width: 145px" src="@/assets/common/nodata.png" alt="" />
        <p style="margin-top: 10px">暂无收货地址，请添加~</p>
      </div>

      <div class="add_btn" @click="add_btn">+ 新建收货地址</div>
      <!-- 添加弹窗 -->
      <el-dialog
        width="675px"
        title="添加收货地址"
        :visible.sync="dialogFormVisible"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <el-form :model="addressform" :rules="rules" ref="addressform">
          <el-form-item
            label="收  货  人"
            prop="consignee"
            :label-width="formLabelWidth"
          >
            <el-input
              style="width: 534px"
              v-model="addressform.consignee"
              autocomplete="off"
              placeholder="收货人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="mobile"
            :label-width="formLabelWidth"
          >
            <el-input
              style="width: 534px"
              v-model="addressform.mobile"
              autocomplete="off"
              placeholder="收货人联系电话"
              clearable
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="所在地区"
            prop="region"
            :label-width="formLabelWidth"
          >
            <el-cascader
              style="width: 534px"
              v-model="selectedOptions"
              :options="options"
              filterable
              clearable
                placeholder="请选择收货人所在的省/市/区"
              @change="handleChange_city"
            />
          </el-form-item>
          <el-form-item
            class="address_"
            label="详细地址"
            prop="street"
            :label-width="formLabelWidth"
          >
            <el-input
              type="textarea"
              style="width: 534px"
              v-model="addressform.street"
              autocomplete="off"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认地址" :label-width="formLabelWidth">
            <el-switch v-model="addressform.is_default"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit('addressform')"
            >保存</el-button
          >
        </div>
      </el-dialog>
      <!-- 编辑弹窗 -->
      <el-dialog
        width="675px"
        title="修改收货地址"
        :visible.sync="edit_dialog_show"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <el-form
          :model="edit_addressform"
          :rules="rules"
          ref="edit_addressform"
        >
          <el-form-item
            label="收  货  人"
            prop="consignee"
            :label-width="formLabelWidth"
          >
            <el-input
              style="width: 534px"
              v-model="edit_addressform.consignee"
              autocomplete="off"
              placeholder="收货人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="mobile"
            :label-width="formLabelWidth"
          >
            <el-input
              style="width: 534px"
              v-model="edit_addressform.mobile"
              autocomplete="off"
              placeholder="收货人联系电话"
              clearable
              maxlength="11"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            label="所在地区"
            prop="region"
            :label-width="formLabelWidth"
          >
            <el-input
              style="width: 534px"
              v-model="edit_addressform.region"
              autocomplete="off"
              placeholder="省/市/区"
              clearable
            ></el-input>
          </el-form-item> -->
              <el-form-item
            label="所在地区"
            prop="region"
            :label-width="formLabelWidth"
          >
            <el-cascader
              style="width: 534px"
              v-model="selectedOptions"
              :options="options"
              filterable
              clearable
              :placeholder="'省/市/区:'+edit_addressform.region"
              @change="handleChange_city"
            />
          </el-form-item>
          <el-form-item
            class="address_"
            label="详细地址"
            prop="street"
            :label-width="formLabelWidth"
          >
            <el-input
              type="textarea"
              style="width: 534px"
              v-model="edit_addressform.street"
              autocomplete="off"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="默认地址" :label-width="formLabelWidth">
            <el-switch v-model="edit_addressform.is_default"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="qxSubmit('edit_addressform')">取 消</el-button>
          <el-button type="primary" @click="edit_Submit('edit_addressform')"
            >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import tab from "@/components/tab.vue";
import api from "@/config/api";
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  //   name: "my_order",
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("请填写收货人联系电话"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      options: regionData,
      selectedOptions: [],
      show_nodata: false,
      title: "",
      address_List: [],
      tab_list: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "待付款",
        },
        {
          id: 2,
          name: "已完成",
        },
      ],
      dialogFormVisible: false,
      edit_dialog_show: false,
      addressform: {
        consignee: "",
        mobile: "",
        region: "",
        street: "",
        is_default: false,
      },
      edit_addressform: {
        consignee: "",
        mobile: "",
        region: "",
        street: "",
        is_default: false,
      },
      formLabelWidth: "80px",
      rules: {
        consignee: [
          { required: true, message: "请填写收货人姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        region: [
          {
            required: true,
            message: "请填写收货人所在省市区",
            trigger: "blur",
          },
        ],
        street: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    // console.log(this.$route.query.re_url);
    this.re_url = this.$route.query.re_url;
  },
  methods: {
    handleChange_city(value) {
      this.addressform.region=CodeToText[this.selectedOptions[0]]+CodeToText[this.selectedOptions[1]]+CodeToText[this.selectedOptions[2]]
      console.log(CodeToText[this.selectedOptions[0]], this.addressform.region);
    },

    async getData() {
      try {
        const res = await api.address_List();
        if (res.code == 0) {
          this.address_List = res.result;
          if (this.address_List.length == 0) {
            this.show_nodata = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 添加
    async add_address(addressform) {
      console.log(addressform);
      
      try {
        const res = await api.add_address(addressform);
        if (res.code == 0) {
          console.log(res.result);
          if (this.addressform.is_default == 1) {
            this.address_List.forEach((v) => {
              v.is_default = 0;
            });
          }

          this.address_List.unshift(this.addressform);

          this.addressform = {
            consignee: "",
            mobile: "",
            region: "",
            street: "",
            is_default: false,
          };
          this.dialogFormVisible = false;
          this.show_nodata = false;
          this.$notify({
            title: "提示信息",
            message: "添加成功",
            type: "success",
          });
          if (this.re_url) {
            setTimeout(() => {
              this.$router.push(this.re_url);
            }, 1000);
          }
        }
      } catch (error) {
        this.$notify({
          title: "提示信息",
          message: "添加失败",
          type: "error",
        });
        console.log(error);
      }
    },
    async _del_address(id) {
      try {
        const res = await api.del_address({ id: id });
        if (res.code == 0) {
          console.log(res.result);
          this.$notify({
            title: "提示信息",
            message: "删除成功",
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "提示信息",
          message: "删除失败",
          type: "error",
        });
      }
    },
    // 编辑
    async edit_address(data) {
      try {
        const res = await api.edit_address(data);
        if (res.code == 0) {
          console.log(res.result);
          this.$notify({
            title: "提示信息",
            message: "修改成功",
            type: "success",
          });
          this.getData();
          this.edit_dialog_show = false;
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "提示信息",
          message: "修改失败",
          type: "error",
        });
      }
    },

    // 是否删除
    is_del_address(id, index) {
      this.$confirm("此操作将永久删除该地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this._del_address(id);
          this.address_List.splice(index, 1);
        })
        .catch(() => {
          this.$notify({
            title: "提示信息",
            message: "已取消删除",
          });
        });
    },

    tab_index(tab_index) {
      console.log(tab_index, "111");
    },
    go_my_order_detail() {
      this.$router.push({
        path: "/mine/my_order_detail",
      });
    },
    add_btn() {
      this.dialogFormVisible = true;
      this.title = "添加收货地址";
    },
    edit_btn(item, index) {
      this.edit_dialog_show = true;
      if (item.is_default == 1) {
        item.is_default = true;
      } else {
        item.is_default = false;
      }
      const object = item;
      console.log(object);

      this.edit_addressform = object;
    },
    chang_is_default(item) {
      console.log(item);
      if (item.is_default == 1) {
        item.is_default = 0;
      } else {
        item.is_default = 1;
      }
      console.log(item);
      this.edit_address(item);
    },

    onSubmit(addressform) {
      this.$refs[addressform].validate((valid) => {
        if (valid) {
          //  console.log(this.addressform);
          if (this.addressform.is_default == true) {
            this.dialogFormVisible = false;
            this.addressform.is_default = 1;
          } else {
            this.addressform.is_default = 0;
          }
          console.log(this.addressform);

          setTimeout(() => {
            this.add_address(this.addressform);
          }, 500);
        } else {
          console.log("error submit!!");

          return false;
        }
      });
      setTimeout(() => {
        this.$refs[addressform].clearValidate();
      }, 2000);
    },
    edit_Submit(edit_addressform) {
      this.$refs[edit_addressform].validate((valid) => {
        if (valid) {
          //  console.log(this.addressform);
          if (this.edit_addressform.is_default == true) {
            this.edit_dialog_show = false;
            this.edit_addressform.is_default = 1;
          } else {
            this.edit_addressform.is_default = 0;
          }
          console.log(this.edit_addressform);

          setTimeout(() => {
            this.edit_address(this.edit_addressform);
          }, 500);
        } else {
          console.log("error submit!!");

          return false;
        }
      });
      setTimeout(() => {
        this.$refs[edit_addressform].clearValidate();
      }, 2000);
    },
    qxSubmit() {
      this.edit_dialog_show = false;
      this.getData();
    },
  },
  components: {
    tab,
  },
};
</script>
<style >
.page_my_address .el-dialog {
  border-radius: 16px !important;
}

.el-dialog__title {
  font-size: 18px;
  
  font-weight: 500;
  color: #333333;
  line-height: 28px;
}
.page_my_address .el-form-item__label {
  font-size: 14px;
  
  font-weight: 500;
  color: #333333;
  line-height: 28px;
}
.address_ .el-input__inner {
  height: 80px;
}

.page_my_address .el-dialog__header {
  padding: 30px 30px 0 30px;
}
.page_my_address .el-form-item__content {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
</style>

<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.is_defult {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 25px;
  font-size: 16px;
  
  font-weight: 500;
  color: #1f1f1f;
  width: fit-content;
}
.is_defult:hover,
.del_btn:hover,
.edit_btn:hover {
  color: #479dff !important;
}

.page_my_address {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
    border-bottom: 1px solid #e9e9e9;
  }
  .connent_wrap {
    .address_item {
      border-bottom: 1px solid #e9e9e9;
      padding: 28px 0 26px;
      .name {
        font-size: 16px;
        
        font-weight: 600;
        color: #151515;
      }
      .center {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          font-size: 18px;
          
          font-weight: 400;
          color: #999999;
          line-height: 28px;
        }
        .right {
          display: flex;
          align-items: center;
          .del_btn,
          .edit_btn {
            display: flex;
            align-items: center;
            margin-left: 40px;
            cursor: pointer;
          }
          img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          font-size: 16px;
          
          font-weight: 500;
          color: #1f1f1f;
        }
      }
      .is_defult {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 25px;
        i {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          background: url(../../assets/mine/no_defult.png) no-repeat;
          background-size: contain;
        }
        font-size: 16px;
        
        font-weight: 500;
        color: #1f1f1f;
      }
      .on {
        i {
          background: url(../../assets/mine/is_defult.png) no-repeat;
          background-size: contain;
        }
      }
    }

    .add_btn {
      width: 202px;
      height: 40px;
      background: #479dff;
      border-radius: 20px;
      text-align: center;
      margin: 0 auto;
      font-size: 18px;
      
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      margin-top: 83px;
      cursor: pointer;
    }
  }
}
</style>